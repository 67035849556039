<template>
  <div class="tab-knowledge">
    <div class="search-wrap">
      <el-form inline @submit.native.prevent>
        <template v-if="!batchOperation">
          <el-form-item>
            <el-input
              v-model="search.searchKey"
              v-debounce="[onSearch, 'input']"
              class="input"
              clearable
              prefix-icon="el-icon-search"
              placeholder="搜索内容标题/ID/创建者"
              @clear="onSearch"
              @keydown.enter.native="onSearch"
            />
          </el-form-item>
          <el-form-item>
            <ksh-select
              v-model="search.isQueryAll"
              placeholder="素材来源"
              @change="onSearch"
            >
              <el-option
                v-for="item in orgSource"
                :key="item.isQueryAll"
                :label="item.title"
                :value="item.isQueryAll"
              />
            </ksh-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="search.tagId"
              style="width: 110px;"
              placeholder="所有产品"
              class="reset-item"
              clearable
              @change="onSearch"
            >
              <el-option
                v-for="product in productList"
                :key="product.tagId"
                :label="product.tagName"
                :value="product.tagId"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-if="!search.isQueryAll">
            <div :class="{ 'select-complex': true }">
              <ksh-select
                v-model="search.categoryId"
                clearable
                placeholder="选择分类"
                class="select"
                @change="onSearch"
              >
                <el-option
                  v-for="item in optionsList"
                  :key="item.categoryId"
                  :label="item.title"
                  :value="item.categoryId"
                />
              </ksh-select>
              <div
                v-if="$auth('acadSaas.materialMgmt.cmDoc')"
                class="right-btn"
                @click="handleParentDialog"
              >
                <svg-icon name="manage-icon" />
              </div>
            </div>
            <!-- <el-button
              icon="el-icon"
              plain
              type="info"
              @click="handleParentDialog"
            >
              <svg-icon name="button-manage" />
            </el-button> -->
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item>
            <el-button type="info" plain @click="onBatchDel">
              批量删除
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="info"
              plain
              @click="onSetMoreTag('isBulkSetTag')"
            >
              批量设置标签/关键信息
            </el-button>
          </el-form-item>
        </template>
      </el-form>
      <div>
        <ksh-button
          type="info"
          plain
          @click="showBatchOperation"
        >
          {{ batchOperation ? "取消" : "" }}批量操作
        </ksh-button>
        <ksh-button
          v-if="$auth('acadSaas.materialMgmt.uploadDoc')"
          icon="el-icon-plus"
          type="primary"
          @click="
            () => {
              dialogVisible = true;
              isBulkOpera = false;
            }
          "
        >
          上传文献
        </ksh-button>
      </div>
    </div>

    <el-table
      ref="multipleTable"
      v-loading="loading"
      row-key="knowledgeId"
      :data="tableData"
      stripe
      style="border: 1px solid #f4f1f5; border-bottom: 0;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="batchOperation"
        reserve-selection
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        prop="knowledgeId"
        label="ID"
        align="center"
        width="240"
        show-overflow-tooltip
      />
      <el-table-column prop="title" width="240" label="文献名称">
        <template slot-scope="scoped">
          <span class="title" @click="onCheck(scoped.row)">{{
            scoped.row.title
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="knowledgeType" label="文献类型" align="center" />
      <el-table-column align="center" label="产品标签">
        <template slot-scope="scoped">
          <el-button
            v-if="!onGetTagName(scoped.row.tagInfos)"
            type="text"
            @click="onSetItemTag(scoped.row)"
          >
            前往设置 >
          </el-button>
          <span v-else>{{ onGetTagName(scoped.row.tagInfos) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="关键信息" show-overflow-tooltip>
        <template slot-scope="scoped">
          <el-button
            v-if="!onGetTagKnowledge(scoped.row.tagInfos)"
            type="text"
            @click="onSetItemTag(scoped.row)"
          >
            前往设置 >
          </el-button>
          <span v-else>{{ onGetTagKnowledge(scoped.row.tagInfos) }}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="category.title" label="所属分类" />
      <el-table-column
        v-if="search.isQueryAll"
        align="center"
        prop="createOrgName"
        label="创建组织"
      />
      <el-table-column
        width="120px"
        prop="dateTime"
        align="center"
        label="创建时间"
      />
      <el-table-column
        width="150px"
        align="center"
        label="操作"
        fixed="right"
      >
        <template slot-scope="scoped">
          <el-button
            v-if="$auth('acadSaas.materialMgmt.editDoc')"
            :disabled="scoped.row.knowledgeType !== 'WORD'"
            type="text"
            @click="onEdit(scoped.row)"
          >
            编辑
          </el-button>
          <el-button
            v-if="$auth('acadSaas.materialMgmt.delDoc')"
            type="text"
            style="color: #e8312f;"
            @click="handelDelMessage(scoped.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :page-sizes="pagination.sizes"
      :hide-on-single-page="false"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
    <!-- 新建文献弹窗 -->
    <tab-knowledge-upload
      v-model="dialogVisible"
      title="新建文献"
      width="450px"
      @handle-show-component="handleShowComponent"
    />

    <el-dialog
      :title="showTitle"
      destroy-on-close
      :visible.sync="showComponent"
      width="50%"
    >
      <component
        :is="componentName"
        ref="upload-component"
        :options-list="optionsList"
        :selected-tag-list="selectedTagList"
        :disabled-ids="disabledIds"
        @change="onTagChange"
        @selectTag="onSelectTag"
      />
      <span slot="footer" class="dialog-footer">
        <ksh-button @click="showComponent = false">取 消</ksh-button>
        <ksh-button
          type="primary"
          @click="handleUploadSubmit"
        >完 成</ksh-button>
      </span>
    </el-dialog>
    <ProductLabel
      :visible.sync="isShowTagModal"
      :disabled-ids="disabledIds"
      :selected-tag-list="selectedTagList"
      :options="{
        SPREAD_DEPARTMENT_GROUP: {
          isShowTab: false,
        },
        CUSTOMIZE_TAG_GROUP: {
          isShowTab: false,
        },
      }"
      @onSubmit="onConfirmTag"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'

import { getArticleList, deleteArticle } from '@/services/materialService.js'
import TabKnowledgeUpload from './components/TabKnowledgeUpload.vue'
import TabKnowledgeUploadPDF from './components/TabKnowledgeUploadPDF'
import TabKnowledgeUploadPPT from './components/TabKnowledgeUploadPPT'
import ProductLabel from '@/bizComponents/ProductLabel'

export default {
  name: 'TabKnowledge',
  components: {
    TabKnowledgeUpload,
    TabKnowledgeUploadPDF,
    TabKnowledgeUploadPPT,
    ProductLabel
  },
  mixins: [paginationMixin],
  props: {
    optionsList: {
      type: Array,
      default: () => []
    },
    handleParentDialog: Function
  },
  data() {
    return {
      loading: false,
      isBulkOpera: false,
      isShowTagModal: false,
      selectedTagList: [],
      isBulkShare: false,
      isBulkSetTag: false,
      disabledIds: [],
      sceneType: 'knowledge',
      search: {
        categoryId: '',
        searchKey: '',
        isQueryAll: true,
        tagId: ''
      },
      tableData: [],
      batchOperation: false,
      multipleSelection: [],
      dialogVisible: false,
      componentName: '',
      showComponent: false,
      orgSource: [
        { isQueryAll: true, title: '所有素材' },
        { isQueryAll: false, title: '我的素材' }
      ],
      productList: []
    }
  },
  computed: {
    showTitle() {
      let title = ''
      switch (this.componentName) {
        case 'TabKnowledgeUploadPDF':
          return (title = '上传PDF')
        case 'TabKnowledgeUploadPPT':
          return (title = '上传PPT')
        case 'TabKnowledgeUploadWORD':
          return (title = '自建图文')
        default:
          break
      }
      return title
    },
    showType() {
      return type => {
        switch (type) {
          case 'PPT':
            return 'ppt'
          case 'PDF':
            return 'pdf'
          default:
            return ''
        }
      }
    }
  },
  mounted() {
    this.getDataList()
    this.getTagGroup().then(res => {
      const row = res.find(item => item.groupName.includes('产品'))
      this.getProdectList(row.id)
    })
  },
  activated() {
    this.getDataList()
    this.getTagGroup().then(res => {
      const row = res.find(item => item.groupName.includes('产品'))
      this.getProdectList(row.id)
    })
  },
  methods: {
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup)
      if (data.length) {
        this.tagGroup = data
      }
      return data
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId
      }
      const { data } = await this.$axios.get(this.$API.tagList, { params })
      if (data.length) {
        this.productList = data
      }
    },
    onGetTagName(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list
        ?.filter(v => v.groupCode === groupCode)
        ?.map(v => v.tagName)
        .join('-')
    },
    onGetTagKnowledge(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list
        ?.filter(v => v.groupCode === groupCode)[0]
        ?.knowledgePointInfos?.map(it => it.knowledgePointName)
        .join('；')
    },
    onSelectTag() {
      this.isShowTagModal = true
    },
    onConfirmTag(selectedList) {
      this.selectedTagList = [...selectedList]
      this.isShowTagModal = false
      if (this.isBulkOpera) {
        return this.onSubmitTag()
      }
    },
    onTagChange(tagList) {
      this.selectedTagList = tagList
    },
    onResetTag() {
      this.selectedTagList = []
      this.disabledIds = []
    },
    onSubmitTag() {
      const memberTagInfo = this.selectedTagList.map(v => {
        return {
          userTagType: 14,
          tagId: v.tagId,
          groupId: v.groupId,
          tagName: v.tagName,
          knowledgePointIds: v.knowledgePointIds
        }
      })
      if (this.isBulkShare) {
        this.onShareList(memberTagInfo)
      } else if (this.isBulkSetTag) {
        this.onSetTag(memberTagInfo)
      }
    },
    onShareList(memberTagInfo) {
      const data = {
        isShareOrCancel: true,
        resourceIds: this.multipleSelection.map(it => it.knowledgeId),
        resourceType: 2,
        memberTagInfo
      }
      this.$axios.post(this.$API.cancelMaterialShare, data).then(
        () => {
          this.$message.success('分享成功')
          this.batchOperation = false
          this.getDataList()
        },
        rea => this.$message.error(rea.message)
      )
    },
    onSetTag(memberTagInfo) {
      const data = {
        itemIds: this.multipleSelection.map(it => it.knowledgeId),
        memberTagInfo
      }
      this.$axios.post(this.$API.batchSetTag, data).then(
        () => {
          this.$message.success('设置成功')
          this.batchOperation = false
          this.getDataList()
        },
        rea => this.$message.error(rea.message)
      )
    },
    onResetValve(bulkType) {
      this.isBulkSetTag = bulkType === 'isBulkSetTag'
      this.isBulkShare = bulkType === 'isBulkShare'
    },
    onOpenTagModal(bulkType) {
      if (!this.multipleSelection.length)
        return this.$message.error('请选择文献')
      this.isBulkOpera = true
      this.isShowTagModal = true
      this.onResetValve(bulkType)
    },
    onSetMoreTag(bulkType) {
      this.disabledIds = []
      this.selectedTagList = []
      this.onOpenTagModal(bulkType)
    },
    onSetItemTag(item) {
      const { tagInfos = [] } = item
      this.disabledIds = tagInfos ? tagInfos?.map(it => it.tagId) : []
      this.selectedTagList = item.tagInfos || []
      this.toggleSelection([item])
      this.onOpenTagModal('isBulkSetTag')
    },
    // 获取列表数据
    async getDataList() {
      this.loading = true
      try {
        const params = this.getParams(this.search)
        const {
          data: { data, totalCount }
        } = await getArticleList(params)
        this.tableData = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    // 查看
    onCheck(row) {
      if (row.knowledgeType === 'WORD') {
        const routeUrl = this.$router.resolve({
          path: `/material/article/preview/${row.knowledgeId}`
        })
        window.open(routeUrl.href, '_blank')
      } else {
        window.open(row.pdfUrl, '_blank')
      }
    },
    // 编辑
    onEdit(row) {
      this.$router.push({
        name: 'KnowledgeEdit',
        query: {
          id: row.knowledgeId
        }
      })
    },
    handelDelMessage(item) {
      this.$MessageDialog({
        title: '删除',
        message: !item.isRealCanDel
          ? '确认删除该素材么？删除后不可再被引用,'
          : '确认删除该素材么？一经删除，不可恢复',
        newLineMessage: !item.isRealCanDel
          ? '您也可以在个人中心回收站重新找回'
          : ''
      }).then(() => {
        this.onDel(item.knowledgeId)
      })
    },
    // 删除
    async onDel(knowledgeIds) {
      try {
        await deleteArticle({ knowledgeIds })
        this.$message.success('删除成功')
        await this.getDataList()
        this.toggleSelection()
      } catch (error) {
        this.$message({
          type: 'error',
          message: error.message
        })
        console.log(error)
      }
    },
    handleShowComponent(name) {
      this.onResetTag()
      this.componentName = name
      this.showComponent = true
    },
    // 搜索
    onSearch() {
      this.pagination.pageNo = 1
      if (this.search.isQueryAll) {
        this.search.categoryId = ''
      }
      this.getDataList()
    },

    // 批量删除
    onBatchDel() {
      if (this.multipleSelection.length !== 0) {
        let cannotRecover = 0
        let canRecover = 0
        this.multipleSelection.map(item => {
          if (item.isRealCanDel) {
            cannotRecover++
          } else {
            canRecover++
          }
        })
        this.$MessageDialog({
          title: '批量删除',
          message: `<div style="margin: 20px;"><div>确定要删除该批素材？${canRecover}个素材将会被放进回收站，</div>${cannotRecover}个素材将会直接删除</div>`
        }).then(() => {
          const ids = this.multipleSelection
            .map(item => item.knowledgeId)
            .join(',')
          this.onDel(ids)
        })
      } else {
        this.$message.error('请先选择要操作的数据项')
      }
    },
    // 回显表格数据
    toggleSelection(rows) {
      this.$refs.multipleTable.clearSelection()
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    showBatchOperation() {
      this.batchOperation = !this.batchOperation
      this.toggleSelection()
    },
    // 处理上传
    async handleUploadSubmit() {
      const currentDom = this.$refs['upload-component']
      currentDom.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            this.uploadLoading = true
            await currentDom.onSubmit()
            this.uploadLoading = false

            this.showComponent = false
            this.dialogVisible = false

            this.getDataList()
          } catch (error) {
            this.uploadLoading = false
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tab-knowledge {
  .search-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .input {
      ::v-deep .el-input__inner {
        border-radius: 20px;
      }
    }
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        > span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          > span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .select-complex {
      display: flex;
      box-sizing: border-box;
      border: 1px solid #dcdfe6;
      background-color: #fbfbfb;
      margin: 1px;
      transition: all 0.3s;
      &:hover {
        border-color: #c0c4cb;
      }
      &:focus {
        border-color: $color-primary;
      }
      ::v-deep .select {
        line-height: 26px;
        .el-input {
          .el-input__inner {
            height: 26px;
            line-height: 26px;
            background-color: transparent;
            border: 1px solid transparent;
            border-right: none;
          }
        }
      }
      .right-btn {
        width: 28px;
        line-height: 26px;
        text-align: center;
        color: #a3a3a3;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 1px;
          height: 12px;
          background-color: #e1e1e1;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
  }
  .pagination {
    margin-top: 10px;
  }
  .title {
    color: #3d61e3;
    cursor: pointer;
  }
}
</style>
