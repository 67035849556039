<template>
  <div class="tab-exam">
    <div class="search-wrap">
      <el-form inline @submit.native.prevent>
        <template v-if="!batchOperation">
          <el-form-item>
            <el-input
              v-model="search.searchKey"
              v-debounce="[onSearch,'input']"
              clearable
              class="input"
              prefix-icon="el-icon-search"
              placeholder="搜索内容标题/ID/创建者"
              @clear="onSearch"
              @keydown.enter.native="onSearch"
            />
          </el-form-item>
          <el-form-item>
            <ksh-select
              v-model="search.isQueryAll"
              placeholder="素材来源"
              @change="onSearch"
            >
              <el-option
                v-for="item in orgSource"
                :key="item.isQueryAll"
                :label="item.title"
                :value="item.isQueryAll"
              />
            </ksh-select>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="search.isProductTagQuery"
              style="width: 110px;"
              placeholder="有产品标签"
              class="reset-item"
              @change="onSearch"
            >
              <el-option label="有产品标签" :value="true" />
              <el-option label="无产品标签" :value="false" />
            </el-select>
          </el-form-item>
          <el-form-item v-if="search.isProductTagQuery">
            <el-select
              v-model="search.productTagId"
              style="width: 110px;"
              placeholder="所有产品"
              class="reset-item"
              clearable
              @change="onSearch"
            >
              <el-option
                v-for="product in productList"
                :key="product.tagId"
                :label="product.tagName"
                :value="product.tagId"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <div v-if="!search.isQueryAll" :class="{ 'select-complex': true }">
              <ksh-select
                v-model="search.categoryId"
                clearable
                placeholder="选择分类"
                class="select"
                @change="onSearch"
              >
                <el-option
                  v-for="item in optionsList"
                  :key="item.categoryId"
                  :label="item.title"
                  :value="item.categoryId"
                />
              </ksh-select>
              <div v-if="$auth('acadSaas.materialMgmt.cmQuestion')" class="right-btn" @click="handleParentDialog">
                <svg-icon name="manage-icon" />
              </div>
            </div>
          </el-form-item>
          <el-form-item>
            <ksh-select
              v-model="search.userType"
              @change="onSearch"
            >
              <el-option
                v-for="item in categorySource"
                :key="item.userType"
                :label="item.title"
                :value="item.userType"
              />
            </ksh-select>
          </el-form-item>
        </template>
        <template v-else>
          <el-form-item>
            <el-button type="info" plain @click="onBatchDel">
              批量删除
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="info" plain @click="onSetMoreTag">批量设置标签/关键信息</el-button>
          </el-form-item>
        </template>
      </el-form>
      <div style="flex: 1; text-align: right;">
        <el-popover
          v-model="showExportPop"
          width="200"
          trigger="manual"
          placement="left"
        >
          <div class="lay-box">是否导出当前数据?</div>
          <el-row type="flex" justify="end">
            <el-button size="mini" type="text" @click="showExportPop = false">取消</el-button>
            <el-button type="primary" size="mini" @click="onExportConfirm">确定</el-button>
          </el-row>
          <span
            v-if="$auth('acadSaas.materialMgmt.exportQuestions')"
            slot="reference"
            plain
            class="text-primary-link l-m dao-data"
            @click="onExport"
          >
            导出当前数据
          </span>
        </el-popover>
        <ksh-button
          v-if="search.userType === '1'"
          type="info"
          plain
          style="margin-right: 10px;"
          @click="showBatchOperation"
        >
          {{ batchOperation? '取消': '' }}批量操作
        </ksh-button>
        <el-dropdown v-if="$auth('acadSaas.materialMgmt.uploadQuestion')" @command="onOpenCreateExam">
          <el-button type="primary">新建题目<i class="el-icon-arrow-down el-icon--right" /></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">学术活动题目</el-dropdown-item>
            <el-dropdown-item command="2">代表培训题目</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <el-table
      ref="multipleTable"
      v-loading="loading"
      :data="tableData"
      row-key="examId"
      stripe
      style="border: 1px solid #f4f1f5; border-bottom: 0;"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        v-if="batchOperation"
        reserve-selection
        type="selection"
        width="55"
        align="center"
      />
      <el-table-column
        align="center"
        prop="examId"
        label="ID"
        width="80"
      />
      <el-table-column
        width="240"
        prop="title"
        label="题目名称"
      >
        <template slot-scope="scoped">
          <span class="title" @click="onCheck(scoped.row)">{{ scoped.row.title | titleFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="产品标签"
      >
        <template slot-scope="scoped">
          <el-button v-if="!onGetTagName(scoped.row.tagInfos)" type="text" @click="onSetItemTag(scoped.row)">前往设置 ></el-button>
          <span v-else>{{ onGetTagName(scoped.row.tagInfos) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="关键信息"
        show-overflow-tooltip
      >
        <template slot-scope="scoped">
          <el-button v-if="!onGetTagKnowledge(scoped.row.tagInfos)" type="text" @click="onSetItemTag(scoped.row)">前往设置 ></el-button>
          <span v-else>{{ onGetTagKnowledge(scoped.row.tagInfos) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="所属分类"
      >
        <template slot-scope="scoped">
          <el-button v-if="!search.isQueryAll" type="text" @click="onSetCategory(scoped.row)">{{ scoped.row.category ? scoped.row.category.title : '前往设置 >' }}</el-button>
          <span v-else>{{ scoped.row.category ? scoped.row.category.title : '/' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        width="150px"
        prop="isSetCorrectAnswer"
        align="center"
        label="是否设置答案"
      >
        <!-- eslint-disable-next-line vue/no-unused-vars -->
        <template slot="header" slot-scope="scoped">
          <el-select
            v-model="search.isSetCorrectAnswer"
            class="holder-color"
            clearable
            placeholder="是否设置答案"
            @change="onSearch"
          >
            <el-option
              v-for="item in [{label: '是', value: true}, {label: '否', value: false}]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
        <template slot-scope="scoped">
          {{ scoped.row.isSetCorrectAnswer ? '是' : '否' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="exmTypeEnum"
        align="center"
        label="题目类型"
      >
        <template slot-scope="scoped">
          {{ scoped.row.examTypeEnum | examTypeFilter }}
        </template>
      </el-table-column>

      <el-table-column
        v-if="search.isQueryAll"
        align="center"
        prop="createOrgName"
        label="创建组织"
      />
      <el-table-column
        align="center"
        prop="createdAt"
        label="创建时间"
      />
      <el-table-column
        align="center"
        prop="prop"
        label="操作"
      >
        <template slot-scope="scoped">
          <el-button v-if="$auth('acadSaas.materialMgmt.editQuestion')" type="text" @click="onEdit(scoped.row)">编辑</el-button>
          <el-button
            v-if="$auth('acadSaas.materialMgmt.delQuestion')"
            type="text"
            style="color: #e8312f;"
            @click="handelDelMessage(scoped.row)"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :page-sizes="pagination.sizes"
      :hide-on-single-page="false"
      class="pagination"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
    <ExamPre
      :visible="isShowPre"
      :item-obj="itemObj"
      :is-show-correct="isSetCorrectAnswer"
      @onClose="onClosePre"
    />
    <ClassificationManagement
      v-model="categoryDialog"
      category-type="exam"
      :exam-id="examId"
      :category-id="categoryId"
      @on-submit="getDataList"
    />
    <ProductLabel
      :visible.sync="isShowTagModal"
      :disabled-ids="disabledIds"
      :selected-tag-list="selectedTagList"
      :options="{
        'SPREAD_DEPARTMENT_GROUP': {
          isShowTab: false
        },
        'CUSTOMIZE_TAG_GROUP': {
          isShowTab: false
        }
      }"
      @onSubmit="onSubmitTag"
    />
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination'
import ClassificationManagement from './components/ClassificationManagement.vue'
import { getExamList, deleteExam } from '@/services/materialService.js'
import ProductLabel from '@/bizComponents/ProductLabel'

import { mapActions } from 'vuex'
export default {
  name: 'TabExam',
  components: {
    ClassificationManagement,
    ProductLabel
  },
  filters: {
    titleFilter(title) {
      const titleNew = title?.replace(/<filter><\/filter>/g, '__ ')?.replace(/,/g, '，')
      return titleNew
    },
    examTypeFilter(type) {
      const obj = {
        single: '单选',
        multiple: '多选',
        text_fill: '问答',
        blank_fill: '填空',
        sort: '排序',
        scale: '量表'
      }
      return obj[type]
    }
  },
  mixins: [paginationMixin],
  props: {
    optionsList: {
      type: Array,
      default: () => []
    },
    handleParentDialog: Function
  },
  data() {
    return {
      loading: false,
      isShowTagModal: false,
      selectedTagList: [],
      disabledIds: [],
      search: {
        categoryId: '',
        searchKey: '',
        isSetCorrectAnswer: '',
        isQueryAll: true,
        userType: '1',
        productTagId: '',
        isProductTagQuery: true
      },
      tableData: [],
      isShowPre: false,
      itemObj: {},
      initExamList: [],
      isNeedExamMap: false,
      batchOperation: false,
      multipleSelection: [],
      categoryDialog: false,
      orgSource: [
        {isQueryAll: true, title: '所有素材'},
        {isQueryAll: false, title: '我的素材'}
      ],
      categorySource: [
        {userType: '1', title: '学术活动素材'},
        {userType: '2', title: '代表培训素材'}
      ],
      knowledgeId: '',
      isChoose: false,
      examIds: '',
      examId: null,
      categoryId: null,
      showExportPop: false,
      isSetCorrectAnswer: false,
      productList: []
    }
  },
  computed: {
    examMap() {
      return this.isNeedExamMap
        ? {
          answerType: 'examTypeEnum',
          categoryId: '[category][categoryId]',
          answersItem: {
            answerId: 'examAnswerId',
            title: 'answer'
          }
        }
        : {}
    },
    personInfo() {
      return {
        ...this.$store.state.user.metadataUserInfo,
        orgLoginVo: {
          ...this.$store.state.user.metadataUserInfo?.orgLoginVo
        }
      }
    }
  },
  mounted() {
    this.getDataList()
    this.getTagGroup().then(res => {
      const row = res.find(item => item.groupName.includes('产品'))
      this.getProdectList(row.id)
    })
  },
  activated() {
    this.getDataList()
  },
  methods: {
    ...mapActions(['getExamDetail']),
    async getTagGroup() {
      const { data } = await this.$axios.get(this.$API.getTagGroup)
      if (data.length) {
        this.tagGroup = data
      }
      return data
    },
    async getProdectList(tagGroupId) {
      const params = {
        tagGroupId
      }
      const { data } = await this.$axios.get(this.$API.tagList, { params })
      if (data.length) {
        this.productList = data
      }
    },
    onGetTagName(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list?.filter(v => v.groupCode === groupCode)?.map(v => v.tagName).join('-')
    },
    onGetTagKnowledge(list = [], groupCode = 'PRODUCT_TAG_GROUP') {
      return list?.filter(v => v.groupCode === groupCode)[0]?.knowledgePointInfos?.map(it => it.knowledgePointName).join('；')
    },
    onSubmitTag(newSelectList) {
      this.isShowTagModal = false
      const memberTagInfo = newSelectList.map(v => {
        return {
          userTagType: 15,
          tagId: v.tagId,
          groupId: v.groupId,
          tagName: v.tagName,
          knowledgePointIds: v.knowledgePointIds
        }
      })
      this.onSetTag(memberTagInfo)
    },
    onSetTag(memberTagInfo) {
      const data = {
        itemIds: this.multipleSelection.map(it => String(it.examId)),
        memberTagInfo
      }
      this.$axios.post(this.$API.batchSetTag, data).then(() => {
        this.$message.success('设置成功')
        this.batchOperation = false
        this.getDataList()
      }, rea => this.$message.error(rea.message))
    },
    onExport() {
      this.showExportPop = true
    },
    onExportConfirm() {
      if (!this.tableData.length) return this.$message.error('暂无需要导出的数据')
      const data = this.getParams(this.search)
      this.$axios.post(this.$API.examListDown, data).then(res => {
        this.$message.success(res.data)
        this.showExportPop = false
      }, rea => {
        this.$message.error(rea.message)
        this.showExportPop = false
      })
    },

    // 获取列表数据
    async getDataList() {
      this.loading = true
      try {
        this.search.suitableType = this.search.userType
        const params = this.getParams(this.search)
        const {data: {data, totalCount}} = await getExamList(params)
        this.tableData = data
        this.pagination.total = totalCount
        this.loading = false
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    onClosePre() {
      this.isShowPre = false
    },
    // 获取题目详情
    onOpenExamPreview(item, operation) {
      this.getExamDetail(item.examId).then(res => {
        this.itemObj = res.data || {}
        // operation === 'onCheck' && (this.isShowPre = true)

        if (operation === 'onEdit') {
          this.initExamList = [res.data]
          this.$router.push({path: `edit/exam?id=${item.examId}&type=${item.userType}`})
        }
        if (operation === 'onCheck') {
          this.isShowPre = true
          this.isSetCorrectAnswer = item.isSetCorrectAnswer
        }
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    // 新建问卷
    onOpenCreateExam(type) {
      this.isNeedExamMap = false
      this.initExamList = []
      this.$router.push(`edit/exam?type=${type}`)
    },
    // 查看
    onCheck(item) {
      this.onOpenExamPreview(item, 'onCheck')
    },
    // 编辑
    onEdit(item) {
      this.isNeedExamMap = true
      this.onOpenExamPreview(item, 'onEdit')
    },
    onOpenTagModal() {
      if (!this.multipleSelection.length) return this.$message.error('请选择题目')
      this.isShowTagModal = true
    },
    onSetMoreTag() {
      this.disabledIds = []
      this.selectedTagList = []
      this.onOpenTagModal()
    },
    onSetItemTag(item) {
      console.log(item, '=========11')
      const {tagInfos = []} = item
      this.disabledIds = tagInfos ? tagInfos?.map(it => it.tagId) : []
      this.selectedTagList = item.tagInfos || []
      this.toggleSelection([item])
      this.onOpenTagModal()
    },
    onSetCategory(row) {
      this.examId = row.examId
      this.categoryId = row.category ? row.category.categoryId : null
      this.categoryDialog = true
    },
    handelDelMessage(item) {
      this.$MessageDialog({
        title: '删除',
        message: !item.isRealCanDel ? '确认删除该素材么？删除后不可再被引用,' : '确认删除该素材么？一经删除，不可恢复',
        newLineMessage: !item.isRealCanDel ? '您也可以在个人中心回收站重新找回' : ''
      }).then(() => {
        this.onDel(item.examId)
      })
    },
    // 删除
    async onDel(examIds) {
      if (this.isDeling) return
      this.isDeling = true
      try {
        await deleteExam({examIds})
        await this.getDataList()
        this.isDeling = false
        this.$message.success('删除成功')
        this.toggleSelection()
      } catch (error) {
        this.$message({
          message: error.message,
          type: 'error'
        })
        this.isDeling = false
      }
    },
    // 批量删除
    onBatchDel() {
      if (this.multipleSelection.length !== 0) {
        let cannotRecover = 0
        let canRecover = 0
        this.multipleSelection.map(item => {
          if (item.isRealCanDel) {
            cannotRecover++
          } else {
            canRecover++
          }
        })
        this.$MessageDialog({
          title: '批量删除',
          message: `<div style="margin: 20px;"><div>确定要删除该批素材？${canRecover}个素材将会被放进回收站，</div>${cannotRecover}个素材将会直接删除</div>`
        }).then(() => {
          const ids = this.multipleSelection.map(item => item.examId).join(',')
          this.onDel(ids)
        })
      } else {
        this.$message.error('请先选择要操作的数据项')
      }
    },
    // 搜索
    onSearch() {
      this.pagination.pageNo = 1
      if (this.search.isQueryAll) {
        this.search.categoryId = ''
      }
      if (!this.search.isProductTagQuery) {
        this.search.productTagId = ''
      }
      this.getDataList()
    },
    // 回显表格数据
    toggleSelection(rows) {
      this.$refs.multipleTable.clearSelection()
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row, true)
        })
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    showBatchOperation() {
      this.batchOperation = !this.batchOperation
      this.toggleSelection()
    },
    handleChange() {
      this.onSearch()
    }
  }
}
</script>

<style scoped lang="scss">
// scss
.tab-exam {
  .search-wrap {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .input {
      ::v-deep .el-input__inner {
        border-radius: 20px;
      }
    }
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        > span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          > span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .select-complex {
      display: flex;
      box-sizing: border-box;
      border: 1px solid #dcdfe6;
      background-color: #fbfbfb;
      margin: 1px;
      transition: all 0.3s;
      &:hover {
        border-color: #c0c4cb;
      }
      &:focus {
        border-color: $color-primary;
      }
      ::v-deep .select {
        line-height: 26px;
        .el-input {
          .el-input__inner {
            height: 26px;
            line-height: 26px;
            background-color: transparent;
            border: 1px solid transparent;
            border-right: none;
          }
        }
      }
      .right-btn {
        width: 28px;
        line-height: 26px;
        text-align: center;
        color: #a3a3a3;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          width: 1px;
          height: 12px;
          background-color: #e1e1e1;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
        &:hover {
          background-color: #ddd;
          cursor: pointer;
        }
      }
    }
  }
  .pagination {
    margin-top: 10px;
  }
}
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.l-m {
  margin-right: 30px;
}
.dao-data {
  display: inline-block;
}
.title {
  color: #3d61e3;
  cursor: pointer;
}
</style>
